<template>
  <div>
    <!-- select 2 demo -->
    <b-modal
      id="modal-select2"
      title="Order Details"
      centered
      ok-only
      size="md"
    >
      <b-card :img-src="cover" img-height="250px" img-alt="Cake image" img-top>
        <!-- <img :src="cover" />  -->
        <template #header>
          <h4 class="mb-0">{{ detailObj.title }}</h4>
          <hr />
        </template>

        <b-row>
          <b-col>
            <b-card-text>
              <h4>Billing</h4>
              <p><b> Price :</b> {{ this.detailObj.price + ' AED' }}</p>
              <p>
                <b> Perlayer Cost :</b>
                {{ this.detailObj.perLayerCost + ' AED' }}
              </p>
            </b-card-text>
          </b-col>
          <b-col>
            <b-card-text>
              <h4>Cake</h4>
              <p><b> Flavour :</b> {{ this.detailObj.flavourName }}</p>
              <p><b> Layer :</b> {{ this.detailObj.layerName }}</p>
              <p><b> Size :</b> {{ this.detailObj.size }}</p>
            </b-card-text>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card-text>
              <h4>Shipping Address</h4>
              <p><b> Address Title :</b> {{ this.detailObj.addressTitle }}</p>
              <p><b> Address :</b> {{ this.detailObj.address }}</p>
              <p><b> Delivery :</b> {{ this.detailObj.deliveryMethod }}</p>
              <p><b> Time :</b> {{ this.detailObj.pickupTime }}</p>
            </b-card-text>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Show data"
      centered
      title="Filters"
    >
      <b-form>
        <b-form-group>
          <h5>Select date range</h5>
          <flat-pickr class="form-control" :config="{ mode: 'range' }" />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-start">
              <div style="padding-right:15px">
                <b-button v-b-modal.modal-login variant="primary">
                  <span class="text-nowrap">Filters</span>
                </b-button>
              </div>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-table :items="items" :fields="fields">
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-b-modal.modal-select2
                @click="openDetails(data.item.orderId)"
              >
                <feather-icon icon="FileTextIcon" />

                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item @click="AcceptOrder(data.item.orderId)">
                <feather-icon icon="CheckIcon" />
                <span class="align-middle ml-50">Accept order</span>
              </b-dropdown-item>

              <b-dropdown-item @click="DenyOrder(data.item.orderId)">
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Decline order</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalOrders"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import useOrdersList from './useOrdersList';
import orderStoreModule from '../orderStoreModule';
import flatPickr from 'vue-flatpickr-component';
import { BFormSelect } from 'bootstrap-vue';
//import Multiselect from "vue-multiselect";
export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    //BImg ,
    //BMedia,
    //BAvatar,
    //BLink,
    //BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    flatPickr,
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  created() {
    this.LoadData();
  },
  data() {
    return {
      cover: '',
      fields: [
        { label: 'Customer', key: 'userName' },
        'date',
        { label: 'Net Total', key: 'netBill' },
        'status',
        'actions',
      ],
      items: [],
      selected: null,
      filterOptions: [
        { text: 'Most sold product', value: 'mostSold' },
        { text: 'Least sold product', value: 'leastSold' },
        { text: 'Most popular product', value: 'mostPopular' },
        { text: 'Least popular product', value: 'leastPopular' },
        { text: 'Most profitable product', value: 'mostProfitable' },
      ],
      rangeDate: null,
      myObj: {
        orderId: 0,
        customerId: 0,
        bill: 0,
        discount: 0,
        modeOfpayment: '',
        vendorId: 0,
        deliveryCharges: 0,
        date: '',
        totalBill: 0,
      },
      detailObj: {},
    };
  },
  methods: {
    openDetails(id) {
      console.log(id);
      var axios = require('axios');

      var config = {
        method: 'get',
        url: 'https://okaaik.fastech.pk/api/oOrders/Detailsbyorders/' + id,
        headers: {
          Authorization:
            'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
        },
      };

      axios(config)
        .then(response => {
          // console.log(JSON.stringify(response.data));

          //  this.data  =  response.data;
          // console.log( response.data);
          //this.detailObj = response.data;
          console.log(response.data[0]);
          this.detailObj = response.data[0];
          console.log(this.detailObj);
          this.cover = this.detailObj.coverImg;
          console.log(this.cover);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    AcceptOrder(id) {
      Swal.fire({
        title: 'Do you want to accept the order?',
        text: "You won't be able to revert this!",
        icon: 'question',
        iconColor: '#FB595f',
        confirmButtonText: `Accept`,
        confirmButtonColor: '#FB595f',
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'put',
            url:
              'https://okaaik.fastech.pk/api/oOrders/UpdateOrderStatus/?id=' +
              id +
              '&check=true',
            headers: {
              Authorization:
                'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
            },
          };
          console.log(config.url);
          axios(config)
            .then(response => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire('Accepted!', '', 'success').then(res => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    DenyOrder(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, deny it!',
      }).then(result => {
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'put',
            url:
              'https://okaaik.fastech.pk/api/oOrders/UpdateOrderStatus/?id=' +
              id +
              '&check=false',
            headers: {
              Authorization:
                'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
            },
          };
          console.log(config.url);
          axios(config)
            .then(response => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire(
                  'Denied!',
                  'Your order has been denied.',
                  'success',
                ).then(res => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    LoadData() {
      var axios = require('axios');

      var config = {
        method: 'get',
        url:
          'https://okaaik.fastech.pk/api/oOrders/getoOrdersbyvendor/' +
          this.$store.state.userData.userID,
        headers: {
          Authorization:
            'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
        },
      };

      axios(config)
        .then(response => {
          // console.log(JSON.stringify(response.data));

          //  this.data  =  response.data;
          // console.log( response.data);
          this.items = [];
          response.data.forEach(elem => {
            elem.date = elem.date.split('T')[0];
            this.items.push(elem);
          });
          console.log(this.items);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },

  // setup() {
  //   const ORDER_APP_STORE_MODULE_NAME = 'app-order'

  //   // Register module
  //   // if (!store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.registerModule(ORDER_APP_STORE_MODULE_NAME, orderStoreModule)

  //   // // UnRegister on leave
  //   // onUnmounted(() => {
  //   //   if (store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDER_APP_STORE_MODULE_NAME)
  //   // })

  //   /*const modalFilterOptions = [
  //     { label: 'Most sold product', value: 'mostSold' },
  //     { label: 'Least sold product', value: 'leastSold' },
  //     { label: 'Most popular product', value: 'mostPopular' },
  //     { label: 'Least popular product', value: 'leastPopular' },
  //     { label: 'Most profitable product', value: 'mostProfitable' },
  //   ]*/

  //   const {

  //     tableColumns,
  //     perPage,
  //     currentPage,
  //     totalOrders,
  //     dataMeta,
  //     perPageOptions,
  //     searchQuery,
  //     sortBy,
  //     isSortDirDesc,
  //     refOrderListTable,
  //     refetchData,

  //    // modalFilter,
  //   } = useOrdersList()

  //   return {

  //     // Sidebar
  //     //isAddNewOrderSidebarActive,

  //     fetchMyOrders,
  //     tableColumns,
  //     perPage,
  //     currentPage,
  //     totalOrders,
  //     dataMeta,
  //     perPageOptions,
  //     searchQuery,
  //     sortBy,
  //     isSortDirDesc,
  //     refOrderListTable,
  //     refetchData,
  //     // Filter
  //     avatarText,
  //     //avatarText,
  //     //modalFilterOptions,
  //     //modalFilter,

  //   }
  // },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
